import React from 'react';

export default () => {
  return (
    <div style={{textAlign:'center'}}>
      <div style={{fontSize:'25vw'}}>
        4
      </div>
      <div style={{fontSize:'25vw',lineHeight:'10%'}}>
        0
      </div>
      <div style={{fontSize:'25vw'}}>
        4
      </div>
    </div>
  );
}
